/**
 * Default state declaration
 */
import { LOAD_ACTIVE_PROJECTS_LIST, REMOVE_ACTIVE_PROJECTS, UPDATE_ACTIVE_PROJECTS } from '../actionType'

const initialState = {
    data: [],
}

/**
 * Update active projects list redux state through reducer depending on key
 *
 * @param {*} state - immutable state for page/ component update when there is changes depending on action
 * @param {*} action - an action contains type of changes, and payload for update
 * @returns
 */
export default function activeProjects(state = initialState, action) {
    switch (action.type) {
        case LOAD_ACTIVE_PROJECTS_LIST: {
            return Object.assign({}, state, {
                data: action.payload,
            })
        }
        case UPDATE_ACTIVE_PROJECTS: {
            const payload = action.payload

            const cloneExistingData = [...state.data]

            if (!payload.keyField) {
                cloneExistingData.push(action.payload.data)
            } else {
                const itemIndexForUpdate = cloneExistingData.findIndex(
                    (each) => {
                        return (
                            each[payload.keyField] ===
                            payload.data[payload.keyField]
                        )
                    }
                )

                if (itemIndexForUpdate >= 0) {
                    cloneExistingData[itemIndexForUpdate] = payload.data
                } else {
                    cloneExistingData.push(action.payload.data)
                }
            }

            return Object.assign({}, state, {
                data: cloneExistingData,
            })
        }
        case REMOVE_ACTIVE_PROJECTS: {
            const payload = action.payload

            const cloneExistingData = [...state.data]
            const itemIndexForDelete = cloneExistingData.findIndex(
                (each) => each[payload.keyField] === payload.keyValue
            )

            if (itemIndexForDelete >= 0) {
                cloneExistingData.splice(itemIndexForDelete, 1)
            }

            return Object.assign({}, state, {
                data: cloneExistingData,
            })
        }
        default:
            return state
    }
}
