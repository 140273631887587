import { Alert, AlertTitle } from '@mui/material'
import { ApiError } from '../interfaces/ErrorType'
import { FunctionComponent } from 'react'

const ErrorMessage: FunctionComponent<ErrorMessageProp> = ({ error }) => {
    const response = error?.response
    const generalErrorMessage = response?.data?.message
    const invalidFields = response?.data?.invalidFields
    const errorCode = response?.status
    const errorMessage = generalErrorMessage
        ? generalErrorMessage
        : 'Unknown Server Error'

    return (
        <Alert severity="error">
            <AlertTitle>Error {errorCode}</AlertTitle>
            {errorMessage}

            {invalidFields?.map((invalidField) => {
                return (
                    <Alert severity="error">
                        <AlertTitle>{invalidField.name}</AlertTitle>
                        {invalidField.message}
                    </Alert>
                )
            })}
        </Alert>
    )
}

interface ErrorMessageProp {
    error?: ApiError | undefined
}

export default ErrorMessage
