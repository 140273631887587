import React, { FunctionComponent, useState } from 'react'
import { Box, IconButton, TextField } from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useMutation } from 'react-query'
import { ApiError } from '../../../../interfaces/ErrorType'
import ButtonExt from '../../../../components/ButtonExt'
import Header from '../../../../components/Header'
import ErrorMessage from '../../../../components/ErrorMessage'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
    ProjectTestCase,
    ProjectTestCaseRequest,
    ProjectTestCaseWrapper,
} from '../../../../interfaces/ProjectType'
import { deleteProjectTestCase, duplicateProjectTestCase, saveProjectTestCase } from '../../../../actions/project'
import TextareaAutosizeExt from '../../../../components/TextareaAutosize'
import { connect } from 'react-redux'
import ConfirmationDialog from '../../../../components/ConfirmationDialog'
import { v4 as uuidv4 } from 'uuid'

const projectTestCaseSchema = yup.object().shape({
    name: yup.string().required('required'),
    description: yup.string().required('required'),
    steps: yup.array().required('required').min(1),
    expectedResults: yup.array().required('required').min(1),
    testData: yup.string().optional(),
    reference: yup.string().optional(),
})

const ProjectTestCaseDetail: FunctionComponent<ProjectTestCaseWrapper> = ({
                                                                   isNew,
                                                                   wrapper,
                                                                   switchProject,
                                                                   callback,
                                                               }) => {

    const navigate = useNavigate()
    const [projectTestCase, setProjectTestCase] = useState<ProjectTestCase | undefined>(wrapper)
    const [projectTestCaseRequest, setProjectTestCaseRequest] = useState<ProjectTestCaseRequest>(wrapper?.id ? {
        id: wrapper!!.id,
        projectId: wrapper!!.projectId,
        name: wrapper!!.name,
        description: wrapper!!.description,
        steps: wrapper!!.steps,
        expectedResults: wrapper!!.expectedResults,
        testData: wrapper!!.testData,
        reference: wrapper!!.reference,
    } : {
        id: undefined,
        projectId: switchProject?.id,
        name: '',
        description: '',
        steps: [],
        expectedResults: [],
        testData: '',
        reference: '',
    })
    const [confirmDelete, setConfirmDelete] = useState(false);

    /**
     * Invoke an action to create/ update project
     * @param {*} e - event
     */
    const onSave = (values: ProjectTestCaseRequest) => {
        projectTestCaseCreateOrUpdateMutation.mutate(values, {
            onSuccess: (data) => {
                setProjectTestCase(data)
                setProjectTestCaseRequest(data)
                values = data
            },
        })
    }

    /**
     * Mutate project test case create/ update
     */
    const projectTestCaseCreateOrUpdateMutation = useMutation<ProjectTestCase, ApiError, ProjectTestCaseRequest>(
        saveProjectTestCase,
    )

    /**
     * Mutate project test case delete
     */
    const projectTestCaseDeleteMutation = useMutation<any, ApiError, ProjectTestCaseRequest>(
        deleteProjectTestCase,
    )

    /**
     * Mutate project test case duplicate
     */
    const projectTestCaseDuplicateMutation = useMutation<ProjectTestCase, ApiError, ProjectTestCaseRequest>(
        duplicateProjectTestCase,
    )

    if (projectTestCaseCreateOrUpdateMutation.isSuccess && isNew) {
        navigate(`/project/testcase`)
    }

    /**
     * Page containing project testcase detail page
     */
    return (
        <Box m='20px'>
            {isNew && (
                <>
                    <Box
                        display="flex"
                        justifyContent="start"
                        mt="20px"
                        style={{ padding: `10px` }}
                    >
                        <IconButton
                            color="secondary"
                            onClick={() => navigate(`/project/testcase`)}
                        >
                            <ArrowBackIcon /> Back
                        </IconButton>
                    </Box>

                    <Header title="Create New Test Case" />
                </>
            )}

            <Box style={{ marginBottom: `2em` }}>
                {projectTestCaseCreateOrUpdateMutation.isError && (
                    <ErrorMessage error={projectTestCaseCreateOrUpdateMutation.error} />
                )}
                {projectTestCaseDuplicateMutation.isError && (
                    <ErrorMessage error={projectTestCaseDuplicateMutation.error} />
                )}
                {projectTestCaseDeleteMutation.isError && (
                    <ErrorMessage error={projectTestCaseDeleteMutation.error} />
                )}
            </Box>

            <Formik
                onSubmit={onSave}
                initialValues={projectTestCaseRequest}
                validationSchema={projectTestCaseSchema}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display='grid'
                            gap='30px'
                            gridTemplateColumns='repeat(2, minmax(0,1fr))'
                        >
                            {values.id && (
                                <TextField
                                    variant='filled'
                                    type='text'
                                    label='Id'
                                    value={values.id}
                                    name='id'
                                />
                            )}

                            {!isNew && (
                                <TextField
                                    variant="filled"
                                    type="text"
                                    label="Number"
                                    value={projectTestCase!!.number}
                                    name="number"
                                />
                            )}
                        </Box>

                        <Box
                            display='grid'
                            mt='20px'
                            gap='30px'
                            gridTemplateColumns='repeat(1, minmax(0,1fr))'
                        >
                            <TextField
                                variant="filled"
                                type="text"
                                label="Name"
                                onChange={handleChange}
                                value={values.name}
                                error={!!touched.name && !!errors.name}
                                helperText={touched.name && errors.name}
                                name="name"
                            />

                            <TextareaAutosizeExt
                                label="Description"
                                name="description"
                                onChangeEvent={handleChange}
                                value={values.description}
                                error={!!touched.description && !!errors.description}
                                helperText={touched.description && errors.description}
                                minRows={10}
                                maxRows={10}
                            />

                            <TextareaAutosizeExt
                                label="Steps"
                                name="steps"
                                onChange={(v) => {
                                    const steps = v.split(/\r?\n/);
                                    setProjectTestCaseRequest({
                                        ...projectTestCaseRequest,
                                        steps: steps
                                    })
                                    values.steps = steps
                                }}
                                value={values.steps.join("\n")}
                                error={!!touched.steps && !!errors.steps}
                                helperText={touched.steps && errors.steps}
                                minRows={10}
                                maxRows={10}
                            />

                            <TextareaAutosizeExt
                                label="Expected Results"
                                name="expectedResults"
                                onChange={(v) => {
                                    const expectedResults = v.split(/\r?\n/);
                                    setProjectTestCaseRequest({
                                        ...projectTestCaseRequest,
                                        expectedResults: expectedResults
                                    })
                                    values.expectedResults = expectedResults
                                }}
                                value={values.expectedResults.join("\n")}
                                error={!!touched.expectedResults && !!errors.expectedResults}
                                helperText={touched.expectedResults && errors.expectedResults}
                                minRows={10}
                                maxRows={10}
                            />

                            <TextareaAutosizeExt
                                label="Test Data"
                                name="testData"
                                onChangeEvent={handleChange}
                                value={values.testData}
                                error={!!touched.testData && !!errors.testData}
                                helperText={touched.testData && errors.testData}
                                minRows={5}
                                maxRows={5}
                            />

                            <TextareaAutosizeExt
                                label="Reference"
                                name="reference"
                                onChangeEvent={handleChange}
                                value={values.reference}
                                error={!!touched.reference && !!errors.reference}
                                helperText={touched.reference && errors.reference}
                                minRows={5}
                                maxRows={5}
                            />
                        </Box>

                        <Box
                            display='grid'
                            mt='20px'
                            gap='30px'
                            gridTemplateColumns='repeat(2, minmax(0,1fr))'
                        >
                            {projectTestCase?.createdBy && (
                                <>
                                    <TextField
                                        variant='filled'
                                        type='text'
                                        label='Created By'
                                        value={projectTestCase.createdBy}
                                        name='createdBy'
                                    />
                                    <TextField
                                        variant='filled'
                                        type='text'
                                        label='Created At'
                                        value={projectTestCase.createdAt}
                                        name='createdBy'
                                    />
                                </>
                            )}

                            {projectTestCase?.updatedBy && (
                                <>
                                    <TextField
                                        variant='filled'
                                        type='text'
                                        label='Updated By'
                                        value={projectTestCase.updatedBy}
                                        name='updatedBy'
                                    />
                                    <TextField
                                        variant='filled'
                                        type='text'
                                        label='Updated At'
                                        value={projectTestCase.updatedAt}
                                        name='updatedAt'
                                    />
                                </>
                            )}
                        </Box>

                        <ConfirmationDialog open={confirmDelete} onConfirm={(confirmed) => {
                            if (confirmed) {
                                projectTestCaseDeleteMutation.mutate(values, {
                                    onSuccess: () => {
                                        if (callback) {
                                            callback(uuidv4())
                                        }
                                    }
                                })
                            }

                            setConfirmDelete(false)
                        }} />

                        <Box
                            display='flex'
                            justifyContent='end'
                            mt='20px'
                            gap='20px'
                        >
                            <ButtonExt
                                type='submit'
                                value={
                                    projectTestCaseCreateOrUpdateMutation.isLoading
                                        ? 'Saving'
                                        : 'Save'
                                }
                                disabled={
                                    projectTestCaseCreateOrUpdateMutation.isLoading
                                }
                            />
                            {!isNew && (
                                <>
                                    <ButtonExt
                                        type='button'
                                        onClickEvent={() => {
                                            setConfirmDelete(true)
                                        }}
                                        value={'Delete'}
                                    />
                                    <ButtonExt
                                        type='button'
                                        onClickEvent={() => {
                                            projectTestCaseDuplicateMutation.mutate(values, {
                                                onSuccess: (data) => {
                                                    alert(`Test Case has successfully duplicated. Search for ${data.number}`)
                                                },
                                            })
                                        }}
                                        value={
                                            projectTestCaseDuplicateMutation.isLoading
                                                ? 'Duplicating...'
                                                : 'Duplicate'
                                        }
                                        disabled={
                                            projectTestCaseDuplicateMutation.isLoading
                                        }
                                    />
                                </>
                            )}
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

/**
 * Connect and retrieve the current switch project id through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { switchProject: state.switchProject.project }
}

export default connect(mapStateToProps)(ProjectTestCaseDetail)