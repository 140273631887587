import React  from 'react'
import { Box } from '@mui/material'
import DataGridFilter, { SearchOptionsProp } from '../../../components/DataGridFilter'
import Header from '../../../components/Header'
import { fetchProject } from '../../../actions/project'
import { Project } from '../../../interfaces/ProjectType'
import AdminProjectDetail from './detail'

function AdminProject() {

    const expandRow = (row: Project) => (
        <AdminProjectDetail isNew={false} wrapper={row} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchProject(searchOptions)
    }

    const columns = [
        {
            dataField: 'code',
            text: 'Code',
            sort: true,
        },
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
        },
    ]

    return (
        <Box m='20px'>
            <Header title='Manage Project' />

            <DataGridFilter
                keyField='id'
                useQueryKey={`admin-projects`}
                columns={columns}
                onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                searchFilterCols={2}
                createPageUrl="/admin/project/create"
                expandRow={expandRow}
            />
        </Box>
    )
}

export default AdminProject