import { Box, IconButton, Menu, MenuItem, useTheme } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { ColorModeContext, tokens } from '../../theme'
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined'
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { signout } from '../../actions/auth'
import AutocompleteExt from '../../components/Autocomplete'
import { connect } from 'react-redux'
import { Project } from '../../interfaces/ProjectType'
import { switchCurrentProject } from '../../actions/project'
import { useMutation } from 'react-query'

const Topbar = (props: { switchProject: Project, activeProjects: Project[] }) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const colorMode = useContext(ColorModeContext)
    const [profileAnchorEl, setProfileAnchorEl] = useState(null)
    const { switchProject, activeProjects } = props

    /**
     * Mutate switch project
     */
    const switchProjectMutation = useMutation<any, Error, Project>(
        switchCurrentProject
    )

    const activeProjectOptions = activeProjects.map((activeProject) => {
        return {
            value: activeProject.id,
            label: `${activeProject.name} (${activeProject.code})`
        }
    })

    return (
        <Box display="flex" justifyContent="space-between" p={2}>
            {/* Switch Project Bar */}
            <Box
                display="flex"
                borderRadius="3px"
                sx={{
                    backgroundColor: colors.primary[400],
                    width: `auto`,
                    minWidth: `300px !important`,

                    "& .MuiFilledInput-root .MuiFilledInput-input": {
                        width: `100% !important`,
                        minWidth: `300px !important`,
                    },
                }}
            >
                {activeProjectOptions && activeProjectOptions.length > 0 && (
                    <AutocompleteExt
                        name='switchProject'
                        multiSelection={false}
                        label='Switch Project'
                        selectedValue={switchProject?.id}
                        options={activeProjectOptions}
                        onSelect={(v) => {
                            const project = activeProjects?.find(each => each.id === v)!!;
                            switchProjectMutation.mutate(project)
                        }}
                    />
                )}
            </Box>

            {/* Icons */}

            <Box display="flex">
                <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === 'dark' ? (
                        <DarkModeOutlinedIcon />
                    ) : (
                        <LightModeOutlinedIcon />
                    )}
                </IconButton>
                <IconButton
                    onClick={(e: any) => setProfileAnchorEl(e.currentTarget)}
                >
                    <PersonOutlinedIcon />
                </IconButton>
                <Menu
                    id="profile-menu"
                    anchorEl={profileAnchorEl}
                    keepMounted
                    open={Boolean(profileAnchorEl)}
                    onClose={() => setProfileAnchorEl(null)}
                >
                    <MenuItem onClick={() => signout()} key="Logout">
                        <ExitToAppIcon /> &nbsp; &nbsp; Logout
                    </MenuItem>
                </Menu>
            </Box>
        </Box>
    )
}

/**
 * Connect and retrieve the current switch project id and active projects through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { switchProject: state.switchProject.project, activeProjects: state.activeProjects.data }
}

export default connect(mapStateToProps)(Topbar)
