/**
 * Default state declaration
 */
import {
    DELETE_TABLE_DATA_ITEM,
    LOAD_TABLE_DATA,
    UPDATE_TABLE_DATA_ITEM,
} from '../actionType'

const initialState = {
    data: [],
}

/**
 * Update table data redux state through reducer depending on key
 *
 * @param {*} state - immutable state for page/ component update when there is changes depending on action
 * @param {*} action - an action contains type of changes, and payload for update
 * @returns
 */
export default function tableData(state = initialState, action) {
    switch (action.type) {
        case LOAD_TABLE_DATA: {
            const payload = action.payload

            const cloneExistingData = [...state.data]
            const existingItem = cloneExistingData.find(
                (each) => each?.key === payload.key
            )
            if (existingItem) {
                existingItem.data = payload.data
            } else {
                cloneExistingData.push(payload)
            }

            return Object.assign({}, state, {
                data: cloneExistingData,
            })
        }
        case UPDATE_TABLE_DATA_ITEM: {
            const payload = action.payload

            const cloneExistingData = [...state.data]
            const existingItem = cloneExistingData.find(
                (each) => each.key === payload.key
            )

            if (existingItem) {
                const itemIndexForUpdate = existingItem.data.findIndex(
                    (each) =>
                        each[payload.keyField] ===
                        payload.data[payload.keyField]
                )

                if (itemIndexForUpdate >= 0) {
                    existingItem.data[itemIndexForUpdate] = payload.data
                }

                return Object.assign({}, state, {
                    data: cloneExistingData,
                })
            }

            return state
        }
        case DELETE_TABLE_DATA_ITEM: {
            const payload = action.payload

            const cloneExistingData = [...state.data]
            const existingItem = cloneExistingData.find(
                (each) => each.key === payload.key
            )

            if (existingItem) {
                const itemIndexForDelete = existingItem.data.findIndex(
                    (each) => each[payload.keyField] === payload.keyValue
                )

                if (itemIndexForDelete >= 0) {
                    existingItem.data.splice(itemIndexForDelete, 1)
                }

                return Object.assign({}, state, {
                    data: cloneExistingData,
                })
            }

            return state
        }
        default:
            return state
    }
}
