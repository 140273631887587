const { EncryptStorage } = require('encrypt-storage')

const secretKey = process.env.REACT_APP_SECRET_KEY

export function getValueByKey(key) {
    try {
        return encryptStorage.getItem(key)
    } catch (error) {
        console.log('Unexpected error occur while decrypting value...')
        encryptStorage.clear()
        window.location.reload()
    }
}

export function updateValueByKey(key, value) {
    encryptStorage.setItem(key, value)
}

export function removeKey(key) {
    encryptStorage.removeItem(key)
}

/**
 * https://www.npmjs.com/package/encrypt-storage#options
 */
const encryptStorage = new EncryptStorage(secretKey)
