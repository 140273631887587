import store from '../redux/store'
import { UNSET_UESR } from '../redux/actionType'
import { fetchActiveProjects } from './project'

export const loadCurrentConfigurations = () => {
    return async () => {
        try {
            await fetchActiveProjects();
        } catch (error) {
            console.log(`Error loading current configuration due to: ${error}`)
        }
    }
}

export const removeAllConfigurations = () => {
    store.dispatch({
        type: UNSET_UESR,
    })
}
