export const projectTestCaseProgressStatusOptions = [
    {
        value: 'PENDING',
        label: 'Pending',
    },
    {
        value: 'IN_PROGRESS',
        label: 'In-Progress',
    },
    {
        value: 'PASS',
        label: 'Pass',
    },
    {
        value: 'BLOCK',
        label: 'Block',
    },
    {
        value: 'FAIL',
        label: 'Fail',
    },
]