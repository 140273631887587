import React, { FunctionComponent, useState } from 'react'
import { Box, TextField } from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useMutation } from 'react-query'
import { ApiError } from '../../../../interfaces/ErrorType'
import ButtonExt from '../../../../components/ButtonExt'
import ErrorMessage from '../../../../components/ErrorMessage'
import {
    ProjectTestCaseProgress, ProjectTestCaseProgressRequest, ProjectTestCaseProgressWrapper,
} from '../../../../interfaces/ProjectType'
import { saveProjectTestCaseProgress } from '../../../../actions/project'
import TextareaAutosizeExt from '../../../../components/TextareaAutosize'
import DateTimePickerExt from '../../../../components/DateTimePickerExt'
import SelectExt from '../../../../components/Select'
import { projectTestCaseProgressStatusOptions } from '../../../../share/ProjectConstant'
import Header from '../../../../components/Header'
import AccordionExt from '../../../../components/AccordionExt'

const projectTestCaseProgressSchema = yup.object().shape({
    actualResults: yup.array().when('status', {
        is: (status: string) => (status !== 'PENDING' && status !== 'IN_PROGRESS'),
        then: yup.array().required('required').min(1)
    }),
    comment: yup.string().when('status', {
        is: (status: string) => (status !== 'PENDING' && status !== 'IN_PROGRESS' && status !== 'PASS'),
        then: yup.string().required('required')
    }),
    reference: yup.string().optional(),
    testedAt: yup.string().when('status', {
        is: (status: string) => (status !== 'PENDING' && status !== 'IN_PROGRESS'),
        then: yup.string().required("required")
    }),
    status: yup.string().required('required'),
})

const ProjectTestCaseProgressDetail: FunctionComponent<ProjectTestCaseProgressWrapper> = ({
                                                                                              isNew,
                                                                                              projectProgressId,
                                                                                              wrapper
}) => {

    const [projectTestCaseProgress, setProjectTestCaseProgress] = useState<ProjectTestCaseProgress>(wrapper!!)
    const [projectTestCaseProgressRequest, setProjectTestCaseProgressRequest] = useState<ProjectTestCaseProgressRequest>({
        actualResults: projectTestCaseProgress.actualResults,
        comment: '',
        reference: projectTestCaseProgress.reference,
        testedAt: projectTestCaseProgress.testedAt,
        status: projectTestCaseProgress.status,
    })

    /**
     * Invoke an action to create/ update project
     * @param {*} e - event
     */
    const onSave = (values: ProjectTestCaseProgressRequest) => {
        projectTestCaseProgressUpdateMutation.mutate({
            projectTestCaseProgress: values,
            projectProgressId: projectProgressId!!,
            testCaseProgressId: projectTestCaseProgress.id
        }, {
            onSuccess: (data) => {
                const update = {
                    ...data,
                    comment: ''
                }
                setProjectTestCaseProgress(update)
                setProjectTestCaseProgressRequest(update)
                values.comment = ''
            },
        })
    }

    /**
     * Mutate project test case progress update
     */
    const projectTestCaseProgressUpdateMutation = useMutation<ProjectTestCaseProgress, ApiError, any>(
        saveProjectTestCaseProgress,
    )

    /**
     * Page containing project testcase progress detail page
     */
    return (
        <Box m='20px'>
            <Box style={{ marginBottom: `2em` }}>
                {projectTestCaseProgressUpdateMutation.isError && (
                    <ErrorMessage error={projectTestCaseProgressUpdateMutation.error} />
                )}
            </Box>

            <Formik
                onSubmit={onSave}
                initialValues={projectTestCaseProgressRequest}
                validationSchema={projectTestCaseProgressSchema}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display='grid'
                            gap='30px'
                            gridTemplateColumns='repeat(2, minmax(0,1fr))'
                        >
                            <TextField
                                variant="filled"
                                type="text"
                                label="Test Case Number"
                                value={projectTestCaseProgress.testCase.number}
                                name="testCase.number"
                            />
                            <TextField
                                variant="filled"
                                type="text"
                                label="Version"
                                value={projectTestCaseProgress.version}
                                name="number"
                            />
                            <TextField
                                variant="filled"
                                type="text"
                                label="Test Case Name"
                                value={projectTestCaseProgress.testCase.name}
                                name="testCase.name"
                            />
                        </Box>

                        <Box
                            display='grid'
                            mt='20px'
                            gap='30px'
                            gridTemplateColumns='repeat(1, minmax(0,1fr))'
                        >
                            <TextareaAutosizeExt
                                label="Description"
                                name="projectTestCaseProgress.testCase.description"
                                value={projectTestCaseProgress.testCase.description}
                                minRows={10}
                                maxRows={10}
                                editable={false}
                            />

                            <TextareaAutosizeExt
                                label="Steps"
                                name="projectTestCaseProgress.testCase.steps"
                                value={projectTestCaseProgress.testCase.steps.join("\n")}
                                minRows={10}
                                maxRows={10}
                                editable={false}
                            />

                            <TextareaAutosizeExt
                                label="Expected Results"
                                name="expectedResults"
                                value={projectTestCaseProgress.testCase.expectedResults.join("\n")}
                                minRows={10}
                                maxRows={10}
                                editable={false}
                            />

                            <TextareaAutosizeExt
                                label="Actual Results"
                                name="actualResults"
                                onChange={(v) => {
                                    const actualResults = v.split(/\r?\n/);
                                    setProjectTestCaseProgressRequest({
                                        ...projectTestCaseProgressRequest,
                                        actualResults: actualResults
                                    })
                                    values.actualResults = actualResults
                                }}
                                value={values.actualResults.join("\n")}
                                error={!!touched.actualResults && !!errors.actualResults}
                                helperText={touched.actualResults && errors.actualResults}
                                minRows={10}
                                maxRows={10}
                            />

                            <TextareaAutosizeExt
                                label="Comment"
                                name="comment"
                                onChangeEvent={handleChange}
                                value={values.comment}
                                error={!!touched.comment && !!errors.comment}
                                helperText={touched.comment && errors.comment}
                                minRows={5}
                                maxRows={5}
                            />

                            <TextareaAutosizeExt
                                label="Test Data"
                                name="projectTestCaseProgress.testCase.testData"
                                value={projectTestCaseProgress.testCase.testData}
                                minRows={5}
                                maxRows={5}
                                editable={false}
                            />

                            <TextareaAutosizeExt
                                label="Reference"
                                name="projectTestCaseProgress.testCase.reference"
                                value={projectTestCaseProgress.testCase.reference}
                                minRows={5}
                                maxRows={5}
                                editable={false}
                            />

                            <DateTimePickerExt
                                label="Tested At"
                                value={values.testedAt}
                                onChange={(v) => {
                                    console.log(v)
                                    setProjectTestCaseProgressRequest({
                                        ...projectTestCaseProgressRequest,
                                        testedAt: v
                                    })
                                    values.testedAt = v
                                }}
                                error={!!touched.testedAt && !!errors.testedAt}
                                helperText={touched.testedAt && errors.testedAt}
                            />

                            <SelectExt
                                name="status"
                                multiSelection={false}
                                isClearable={false}
                                selectedValue={values.status}
                                onSelectEvent={handleChange}
                                options={projectTestCaseProgressStatusOptions}
                                error={!!touched.status && !!errors.status}
                                helperText={touched.status && errors.status}
                            />
                        </Box>

                        <Box
                            display='grid'
                            mt='20px'
                            gap='30px'
                            gridTemplateColumns='repeat(2, minmax(0,1fr))'
                        >
                            {projectTestCaseProgress?.createdBy && (
                                <>
                                    <TextField
                                        variant='filled'
                                        type='text'
                                        label='Created By'
                                        value={projectTestCaseProgress.createdBy}
                                        name='createdBy'
                                    />
                                    <TextField
                                        variant='filled'
                                        type='text'
                                        label='Created At'
                                        value={projectTestCaseProgress.createdAt}
                                        name='createdBy'
                                    />
                                </>
                            )}

                            {projectTestCaseProgress?.updatedBy && (
                                <>
                                    <TextField
                                        variant='filled'
                                        type='text'
                                        label='Updated By'
                                        value={projectTestCaseProgress.updatedBy}
                                        name='updatedBy'
                                    />
                                    <TextField
                                        variant='filled'
                                        type='text'
                                        label='Updated At'
                                        value={projectTestCaseProgress.updatedAt}
                                        name='updatedAt'
                                    />
                                </>
                            )}
                        </Box>

                        <Box
                            display='grid'
                            gap='30px'
                            mt="30px"
                            gridTemplateColumns='repeat(1, minmax(0,1fr))'
                        >
                            <Header title="Histories" titleVariant="h4" />
                            {projectTestCaseProgress.histories.map((history) => {
                                return (
                                    <AccordionExt
                                        title={`Status: ${history.status}`}
                                        component={
                                            <>
                                                <TextareaAutosizeExt
                                                    label="Expected Results"
                                                    name="expectedResults"
                                                    value={history.testCase.expectedResults.join("\n")}
                                                    minRows={10}
                                                    maxRows={10}
                                                    editable={false}
                                                />
                                                <TextareaAutosizeExt
                                                    label="Actual Results"
                                                    name="actualResults"
                                                    value={history.actualResults.join("\n")}
                                                    minRows={10}
                                                    maxRows={10}
                                                    editable={false}
                                                />
                                                <TextareaAutosizeExt
                                                    label="Comment"
                                                    name="comment"
                                                    value={history.comment}
                                                    minRows={5}
                                                    maxRows={5}
                                                    editable={false}
                                                />
                                                <TextField
                                                    variant='filled'
                                                    type='text'
                                                    label='Tested At'
                                                    value={history.testedAt ? history.testedAt : '-'}
                                                    name='testedAt'
                                                    disabled={true}
                                                />
                                            </>
                                        }
                                    />
                                )
                            })}
                        </Box>

                        {projectTestCaseProgress.status !== 'COMPLETE' && (
                            <Box
                                display='flex'
                                justifyContent='end'
                                mt='20px'
                                gap='20px'
                            >
                                <ButtonExt
                                    type='submit'
                                    value={
                                        projectTestCaseProgressUpdateMutation.isLoading
                                            ? 'Saving'
                                            : 'Save'
                                    }
                                    disabled={
                                        projectTestCaseProgressUpdateMutation.isLoading ||
                                        values.status === 'PENDING'
                                    }
                                />
                            </Box>
                        )}
                    </form>
                )}
            </Formik>
        </Box>
    )
}

export default ProjectTestCaseProgressDetail