import { ColorModeContext, useMode } from './theme'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { Routes, Route } from 'react-router-dom'
import Topbar from './scenes/global/Topbar'
import Sidebar from './scenes/global/Sidebar'
import Dashboard from './scenes/dashboard'
import Login from './scenes/login'
import { QueryClient, QueryClientProvider } from 'react-query'
import { useAuth } from './actions/auth'
import { Provider } from 'react-redux'
import store from './redux/store'
import { loadCurrentConfigurations } from './actions/configuration'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import React from 'react'
import AdminProject from './scenes/admin/project'
import AdminProjectDetail from './scenes/admin/project/detail'
import ProjectTestCaseDetail from './scenes/project/testcase/detail'
import ProjectTestCases from './scenes/project/testcase'
import ManageProjectProgress from './scenes/project/progress'

function App() {
    const [theme, colorMode] = useMode()
    const [isAuthenticated] = useAuth()
    const queryClient = new QueryClient()

    /**
     * Load current configurations upon startup
     */
    store.dispatch(loadCurrentConfigurations())

    return (
        <DndProvider backend={HTML5Backend}>
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    <ColorModeContext.Provider value={colorMode}>
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            <div className="app">
                                {!isAuthenticated && <Login />}
                                {isAuthenticated && <Sidebar />}
                                <main className="content">
                                    {isAuthenticated && (
                                        <>
                                            <Topbar />
                                            <Routes>
                                                <Route
                                                    path="/"
                                                    element={<Dashboard />}
                                                />

                                                {store.getState().user.user?.username && store.getState().user.user?.username.toLowerCase() === 'admin' && (
                                                    <>
                                                        <Route
                                                            path="/admin/project"
                                                            element={
                                                                <AdminProject />
                                                            }
                                                        />

                                                        <Route
                                                            path="/admin/project/create"
                                                            element={
                                                                <AdminProjectDetail isNew={true} />
                                                            }
                                                        />
                                                    </>
                                                )}

                                                <Route
                                                    path="/project/testcase"
                                                    element={
                                                        <ProjectTestCases />
                                                    }
                                                />

                                                <Route
                                                    path="/project/testcase/create"
                                                    element={
                                                        <ProjectTestCaseDetail isNew={true} />
                                                    }
                                                />

                                                <Route
                                                    path="/project/progress"
                                                    element={
                                                        <ManageProjectProgress />
                                                    }
                                                />

                                            </Routes>
                                        </>
                                    )}
                                </main>
                            </div>
                        </ThemeProvider>
                    </ColorModeContext.Provider>
                </QueryClientProvider>
            </Provider>
        </DndProvider>
    )
}

export default App
