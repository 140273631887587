// User
export const UPDATE_USER = 'UPDATE_USER'
export const UNSET_UESR = 'UNSET_UESR'

// Table Data
export const LOAD_TABLE_DATA = 'LOAD_TABLE_DATA'
export const UPDATE_TABLE_DATA_ITEM = 'UPDATE_TABLE_DATA_ITEM'
export const DELETE_TABLE_DATA_ITEM = 'DELETE_TABLE_DATA_ITEM'

// Tabs
export const UPDATE_TAB_INDEX = 'UPDATE_TAB_INDEX'

// Projects
export const SWITCH_PROJECT = 'SWITCH_PROJECT'
export const UPDATE_PROJECT = 'UPDATE_PROJECT'
export const UNSET_PROJECT = 'UNSET_PROJECT'

// Active Project List
export const LOAD_ACTIVE_PROJECTS_LIST = 'LOAD_ACTIVE_PROJECTS_LIST'
export const UPDATE_ACTIVE_PROJECTS = 'UPDATE_ACTIVE_PROJECTS'
export const REMOVE_ACTIVE_PROJECTS = 'REMOVE_ACTIVE_PROJECTS'
