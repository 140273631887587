import React, { useEffect, useState } from 'react'
import { Alert, AlertTitle, Box, TextField } from '@mui/material'
import DataGridFilter, { SearchOptionsProp } from '../../../components/DataGridFilter'
import Header from '../../../components/Header'
import {
    closeProjectProgress, downloadProjectProgressTestReport, downloadProjectProgressTestSummaryReport, fetchProjectById,
    fetchProjectProgressById, switchCurrentProject,
} from '../../../actions/project'
import {
    Project, ProjectProgress, ProjectProgressCustomFilterOptions, ProjectProgressRequest,
    ProjectTestCaseProgress,
} from '../../../interfaces/ProjectType'
import { connect } from 'react-redux'
import { useAuthQueryWithQueryFunction } from '../../../extensions/UseAuthQuery'
import { ApiError } from '../../../interfaces/ErrorType'
import ErrorMessage from '../../../components/ErrorMessage'
import SelectExt from '../../../components/Select'
import { projectTestCaseProgressStatusOptions } from '../../../share/ProjectConstant'
import ProjectTestCaseProgressDetail from './detail'
import ButtonExt from '../../../components/ButtonExt'
import { useMutation } from 'react-query'

const ManageProjectProgress = (props: {switchProject: Project}) => {
    const {switchProject} = props

    /**
     * Project progress query
     */
    const projectProgressQuery = useAuthQueryWithQueryFunction<any,
        ApiError,
        ProjectProgress>(['project-progress', switchProject?.id, switchProject?.projectProgressIds],
        () => fetchProjectProgressById(switchProject?.id, switchProject?.projectProgressIds, undefined), {
        onSuccess(data) {
            setProjectProgress(data)
        },
        refetchOnWindowFocus: false,
        enabled: false,
    })

    /**
     * Mutate project progress complete
     */
    const projectProgressCompleteMutation = useMutation<ProjectProgress, ApiError, any>(
        closeProjectProgress,
    )

    const downloadProjectTestReportMutation = useMutation<ProjectProgress, ApiError, any>(
        downloadProjectProgressTestReport
    )

    const downloadProjectTestSummaryReportMutation = useMutation<ProjectProgress, ApiError, any>(
        downloadProjectProgressTestSummaryReport
    )

    useEffect(() => {
        if (switchProject?.id && switchProject.projectProgressIds?.length > 0) {
            projectProgressQuery.refetch()
        }
    }, [switchProject?.id])

    const [projectProgress, setProjectProgress] = useState<ProjectProgress>()

    const [customSearchOptions, setCustomSearchOptions] =
        useState<ProjectProgressCustomFilterOptions>({
            statuses: [],
        })

    const expandRow = (row: ProjectTestCaseProgress ) => (
        <ProjectTestCaseProgressDetail projectProgressId={projectProgress!!.id!!} wrapper={row} />
    )

    const onSearchPageUseQueryEvent = async (searchOptions: SearchOptionsProp) => {
        const data = await fetchProjectProgressById(switchProject.id, switchProject.projectProgressIds, searchOptions)
        return data.testCaseProgresses;
    }

    /**
     * Mutate switch project
     */
    const switchProjectMutation = useMutation<any, Error, Project>(
        switchCurrentProject
    )

    /**
     * Available project query
     */
    const projectQuery = useAuthQueryWithQueryFunction<any,
        ApiError,
        Project>(['project', switchProject?.id], () => fetchProjectById(switchProject?.id), {
        onSuccess(data) {
            console.log(data)
            switchProjectMutation.mutate(data)
        },
        refetchOnWindowFocus: false,
        enabled: false,
    })

    const completeProjectProgress = () => {
        projectProgressCompleteMutation.mutate(projectProgress?.id, {
            onSuccess(data) {
                setProjectProgress(data)
                projectQuery.refetch()
            }
        })
    }

    const downloadProjectTestReport = () => {
        downloadProjectTestReportMutation.mutate(projectProgress)
    }

    const downloadProjectTestSummaryReport = () => {
        downloadProjectTestSummaryReportMutation.mutate(projectProgress)
    }

    const customSearchOptionsRenderer = () => (
        <>
            <SelectExt
                name="statuses"
                multiSelection={true}
                label="Status"
                selectedValue={customSearchOptions.statuses}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        statuses: value,
                    })
                }}
                options={projectTestCaseProgressStatusOptions}
            />
        </>
    )

    const columns = [
        {
            dataField: 'testCase.number',
            text: 'Test Case Number',
            sort: false,
        },
        {
            dataField: 'version',
            text: 'Version',
            sort: false,
        },
        {
            dataField: 'testCase.name',
            text: 'Test Case Name',
            sort: false,
        },
        {
            dataField: 'testedAt',
            text: 'Tested At ',
            sort: false,
        },
        {
            dataField: 'status',
            text: 'Status ',
            sort: false,
        },
    ]

    return (
        <Box m='20px'>
            <Header title={`Project (${switchProject?.name}) Progress`} />

            {!switchProject?.id && (
                <Alert severity="warning">
                    <AlertTitle>Attention</AlertTitle>
                    Please select a project before proceeding.
                </Alert>
            )}

            {switchProject?.id && switchProject.projectProgressIds.length === 0 && (
                <Alert severity="warning">
                    <AlertTitle>Attention</AlertTitle>
                    You must start a progress first
                </Alert>
            )}

            <Box style={{marginBottom: `2em`}}>
                {projectProgressQuery.isError && (
                    <ErrorMessage error={projectProgressQuery.error}/>
                )}

                {projectProgressCompleteMutation.isError && (
                    <ErrorMessage error={projectProgressCompleteMutation.error}/>
                )}
            </Box>

            {projectProgress?.id && (
                <>
                    <Box
                        display='grid'
                        mt="20px"
                        gap='30px'
                        gridTemplateColumns='repeat(2, minmax(0,1fr))'
                    >
                        <TextField
                            variant='filled'
                            type='text'
                            label='Id'
                            value={projectProgress.id}
                            name='id'
                        />

                        <TextField
                            variant='filled'
                            type='text'
                            label='Version'
                            value={projectProgress.version}
                            name='version'
                        />

                        <TextField
                            variant='filled'
                            type='text'
                            label='Project End'
                            value={projectProgress.projectEnd}
                            name='projectEnd'
                        />

                        <TextField
                            variant='filled'
                            type='text'
                            label='Status'
                            value={projectProgress.status}
                            name='status'
                        />
                    </Box>

                    {projectProgress.status !== 'COMPLETE' && (
                        <Box
                            display='flex'
                            justifyContent='start'
                            mt='20px'
                            gap='20px'
                        >
                            <ButtonExt
                                type='button'
                                onClickEvent={completeProjectProgress}
                                value={
                                    projectProgressCompleteMutation.isLoading
                                        ? 'Closing...'
                                        : 'Close Current Progress'
                                }
                                disabled={
                                    projectProgressCompleteMutation.isLoading
                                }
                            />
                            <ButtonExt
                                type='button'
                                onClickEvent={downloadProjectTestReport}
                                value={
                                    downloadProjectTestReportMutation.isLoading
                                        ? 'Generating...'
                                        : 'Generate Current Test Report'
                                }
                                disabled={
                                    downloadProjectTestReportMutation.isLoading
                                }
                            />
                        </Box>
                    )}

                    {projectProgress.status === 'COMPLETE' && (
                        <Box
                            display='flex'
                            justifyContent='start'
                            mt='20px'
                            gap='20px'
                        >
                            <ButtonExt
                                type='button'
                                onClickEvent={downloadProjectTestReport}
                                value={
                                    downloadProjectTestReportMutation.isLoading
                                        ? 'Generating...'
                                        : 'Generate Latest Test Report'
                                }
                                disabled={
                                    downloadProjectTestReportMutation.isLoading
                                }
                            />

                            <ButtonExt
                                type='button'
                                onClickEvent={downloadProjectTestSummaryReport}
                                value={
                                    downloadProjectTestSummaryReportMutation.isLoading
                                        ? 'Generating...'
                                        : 'Generate Latest Test Summary Report'
                                }
                                disabled={
                                    downloadProjectTestSummaryReportMutation.isLoading
                                }
                            />
                        </Box>
                    )}

                    <Box mt="30px">

                        <Header title="Test Cases" titleVariant="h4" />

                        <DataGridFilter
                            keyField='id'
                            useQueryKey={`project-test-case-progress-${switchProject.id}`}
                            columns={columns}
                            customSearchOptions={customSearchOptions}
                            resetCustomSearchOptions={setCustomSearchOptions}
                            customSearchOptionsRenderer={customSearchOptionsRenderer()}
                            onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                            searchFilterCols={3}
                            expandRow={expandRow}
                        />
                    </Box>
                </>
            )}
        </Box>
    )
}

/**
 * Connect and retrieve the current switch project id through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { switchProject: state.switchProject.project }
}

export default connect(mapStateToProps)(ManageProjectProgress)