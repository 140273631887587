import React, { useEffect, useState } from 'react'
import { Alert, AlertTitle, Box, IconButton } from '@mui/material'
import DataGridFilter, { SearchOptionsProp } from '../../../components/DataGridFilter'
import Header from '../../../components/Header'
import {
    fetchProjectTestCase,
    fetchProjectById, saveProjectProgress, switchCurrentProject,
} from '../../../actions/project'
import {
    Project,
    ProjectProgressRequest,
    ProjectTestCase,
} from '../../../interfaces/ProjectType'
import { connect } from 'react-redux'
import ProjectTestCaseDetail from './detail'
import { useAuthQueryWithQueryFunction } from '../../../extensions/UseAuthQuery'
import { ApiError } from '../../../interfaces/ErrorType'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate } from 'react-router-dom'
import ButtonExt from '../../../components/ButtonExt'
import { useMutation } from 'react-query'
import DateTimePickerExt from '../../../components/DateTimePickerExt'

const ProjectTestCases = (props: {switchProject: Project}) => {
    const { switchProject } = props
    const navigate = useNavigate()
    const [change, setChange] = useState<string>()

    /**
     * Mutate switch project
     */
    const switchProjectMutation = useMutation<any, Error, Project>(
        switchCurrentProject
    )

    /**
     * Available project query
     */
    const projectQuery = useAuthQueryWithQueryFunction<undefined,
        ApiError,
        Project>(['project', switchProject?.id], () => fetchProjectById(switchProject?.id), {
            onSuccess(data) {
                switchProjectMutation.mutate(data)
            },
            refetchOnWindowFocus: false,
            enabled: false,
    })

    /**
     * Mutate project test case create/ update
     */
    const projectProgressCreateMutation = useMutation<any, ApiError, ProjectProgressRequest>(
        saveProjectProgress,
    )

    useEffect(() => {
        projectQuery.refetch()
    }, [switchProject?.id])

    const [projectProgress, setProjectProgress] = useState<ProjectProgressRequest>({
        projectId: switchProject?.id,
        projectEnd: undefined,
    })

    const createProjectProgress = () => {
        projectProgressCreateMutation.mutate(projectProgress, {
            onSuccess(data) {
                projectQuery.refetch()
            }
        });
    }

    const expandRow = (row: ProjectTestCase) => (
        <ProjectTestCaseDetail isNew={false} wrapper={row} callback={setChange} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchProjectTestCase(searchOptions)
    }

    const columns = [
        {
            dataField: 'number',
            text: 'Number',
            sort: true,
        },
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
        },
        {
            dataField: 'reference',
            text: 'Reference ',
            sort: false,
        },
    ]

    return (
        <Box m='20px'>
            <Header title='Project Test Cases' />

            {!switchProject?.id && (
                <Alert severity="warning">
                    <AlertTitle>Attention</AlertTitle>
                    Please select a project before proceeding.
                </Alert>
            )}

            {switchProject?.id && (
                <>
                    {!switchProject.inTesting && (
                        <>
                            <Box
                                display='flex'
                                justifyContent='start'
                                mt="20px"
                                gap="20px"
                                style={{ padding: `10px` }}
                            >
                                <DateTimePickerExt
                                    label="Project End"
                                    value={projectProgress.projectEnd}
                                    onChange={(value) => {
                                        setProjectProgress({
                                            ...projectProgress,
                                            projectEnd: value,
                                        })
                                    }}
                                    min={new Date().toISOString()}
                                />
                                <ButtonExt
                                    type='button'
                                    value={
                                        projectProgressCreateMutation.isLoading
                                            ? 'Creating...'
                                            : 'Create New Progress'
                                    }
                                    onClickEvent={createProjectProgress}
                                    disabled={
                                        projectProgressCreateMutation.isLoading ||
                                        !projectProgress.projectEnd
                                    }
                                />
                            </Box>
                        </>
                    )}

                    {switchProject?.inTesting && (
                        <>
                            <Box
                                display="flex"
                                justifyContent="start"
                                mt="20px"
                                style={{ padding: `10px` }}
                            >
                                <IconButton
                                    color="secondary"
                                    onClick={() => navigate(`/project/progress`)}
                                >
                                    <NavigateNextIcon /> Progress
                                </IconButton>
                            </Box>
                        </>
                    )}

                    <DataGridFilter
                        keyField='id'
                        useQueryKey={`project-test-case-${switchProject.id}`}
                        columns={columns}
                        onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                        change={change}
                        searchFilterCols={2}
                        createPageUrl="/project/testcase/create"
                        expandRow={expandRow}
                    />
                </>
            )}

        </Box>
    )
}

/**
 * Connect and retrieve the current switch project id through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { switchProject: state.switchProject.project }
}

export default connect(mapStateToProps)(ProjectTestCases)