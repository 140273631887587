import React  from 'react';
import styled from 'styled-components';
import ButtonExt from './ButtonExt'

interface ConfirmationDialogProps {
    open: boolean
    onConfirm: (confirmed: boolean) => void;
}

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value as per your preference */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: inherit;
  padding: 20px;
  border-radius: 4px;
`;

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ open, onConfirm }) => {
    const handleConfirm = () => {
        onConfirm(true);
    };

    const handleCancel = () => {
        onConfirm(false);
    };

    return (
        <>
            {open && (
                <ModalOverlay>
                    <ModalContent>
                        <p style={{color: 'white'}}>Are you sure you want to proceed?</p>
                        <ButtonExt style={{margin: `5px`}} value="Yes" onClickEvent={handleConfirm} />
                        <ButtonExt style={{margin: `5px`}} value="No" onClickEvent={handleCancel} />
                    </ModalContent>
                </ModalOverlay>
            )}
        </>
    );
};

export default ConfirmationDialog;