import {
    getValueByKey,
    updateValueByKey,
    removeKey,
} from '../../extensions/EncryptStorage'
import { CURRENT_PROJECT } from '../../actions/project'
import { SWITCH_PROJECT, UPDATE_PROJECT, UNSET_PROJECT } from '../actionType'

/**
 * Default state declaration
 */
const initialState = {
    project: getValueByKey(CURRENT_PROJECT),
}

/**
 * Update current project id redux state through reducer
 *
 * @param {*} state - immutable state for page/ component update when there is changes depending on action
 * @param {*} action - an action contains type of changes, and payload for update
 * @returns
 */
export default function switchProject(state = initialState, action) {
    switch (action.type) {
        case SWITCH_PROJECT:
        case UPDATE_PROJECT: {
            updateValueByKey(CURRENT_PROJECT, action.payload)
            return Object.assign({}, state, {
                project: action.payload,
            })
        }
        case UNSET_PROJECT: {
            removeKey(CURRENT_PROJECT)
            return Object.assign({}, state, {
                project: undefined,
            })
        }
        default:
            return state
    }
}
