import React, { useEffect, useState } from 'react'
import {
    Box,
    IconButton,
    TablePagination,
    TableSortLabel,
    useTheme,
} from '@mui/material'
import { tokens } from '../theme'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

interface DataGridProp {
    keyField: string
    columns: DataGridColumnDescription[]
    data: any[]
    expandRow: (row: any) => any
    paginationFactory: PaginationFactory
    onTableChange: (
        type: string,
        valueChange: TablePaginationChangeProp
    ) => void
}

export interface PaginationFactory {
    page: number
    totalSize: number
    sizePerPage: number
}

export interface DataGridColumnDescription {
    dataField: string
    text: string
    sort?: boolean | undefined
}

export interface TablePaginationChangeProp {
    page: number
    sizePerPage: number
    sortField?: string | undefined
    sortOrder?: string | undefined
}

function DataGrid({
    keyField,
    columns,
    data,
    expandRow,
    paginationFactory,
    onTableChange,
}: DataGridProp) {
    const [expandedRows, setExpandedRows] = useState<number[]>([])
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const rowPerPageOptions = [10, 25, 50, 100]
    const [page, setPage] = useState(1)
    const [sizePerPage, setSizePerPage] = useState(rowPerPageOptions[rowPerPageOptions.length - 1])
    const [sortField, setSortField] = useState<string | undefined>()
    const [sortOrder, setSortOrder] = useState<string | undefined>()

    useEffect(() => {
        setExpandedRows([])
    }, [data])

    const toggleRow = (index: number) => {
        if (expandedRows.includes(index)) {
            setExpandedRows([])
        } else {
            setExpandedRows([index])
        }
    }

    const onInternalTableChange = (
        type: string,
        valueChange: TablePaginationChangeProp
    ) => {
        if (type === 'pagination') {
            setPage(valueChange.page)
            setSizePerPage(valueChange.sizePerPage)
        }

        if (type === 'sort') {
            valueChange.sortOrder = 'asc'
            if (sortOrder && sortField === valueChange.sortField) {
                valueChange.sortOrder = sortOrder === 'asc' ? 'desc' : 'asc'
            }
            setSortField(valueChange.sortField)
            setSortOrder(valueChange.sortOrder)
        }

        onTableChange(type, valueChange)
    }

    return (
        <Box m="20px">
            <Box
                m="40px 0 0 0"
                sx={{
                    '& .MuiPaper-TableContainer': {
                        minHeight: '50vh',
                    },
                    '& .MuiTableContainer-root': {
                        backgroundColor:
                            theme.palette.mode === 'dark'
                                ? colors.primary[500]
                                : colors.primary[400],
                    },
                    '& .MuiTableCell-root': {
                        border: 'none',
                        borderBottom: `1px solid ${
                            theme.palette.mode === 'dark'
                                ? colors.grey[500]
                                : colors.grey[800]
                        }`,
                    },
                    '& .MuiTableCell-head': {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: 'none',
                    },
                    '& .MuiTableRowMain-root': {
                        cursor: 'pointer',
                    },
                    '& .MuiTableRowMain-root:hover': {
                        backgroundColor:
                            theme.palette.mode === 'dark'
                                ? colors.primary[500]
                                : colors.primary[400],
                        filter: `brightness(${
                            theme.palette.mode === 'dark' ? '200%' : '95%'
                        })`,
                        cursor: 'pointer',
                    },
                    '& .MuiTablePagination-root': {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: 'none',
                    },
                }}
            >
                <TableContainer
                    className="MuiPaper-TableContainer"
                    key={keyField}
                    component={Paper}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    style={{ width: `10px` }}
                                ></TableCell>
                                {columns &&
                                    columns.map((column) => {
                                        if (column.sort) {
                                            return (
                                                <TableCell
                                                    key={column.dataField}
                                                    sortDirection={
                                                        sortOrder === 'desc'
                                                            ? 'desc'
                                                            : sortOrder ===
                                                              'asc'
                                                            ? 'asc'
                                                            : undefined
                                                    }
                                                >
                                                    <TableSortLabel
                                                        active={
                                                            sortField ===
                                                            column.dataField
                                                        }
                                                        direction={
                                                            sortOrder === 'desc'
                                                                ? 'desc'
                                                                : sortOrder ===
                                                                  'asc'
                                                                ? 'asc'
                                                                : undefined
                                                        }
                                                        onClick={() =>
                                                            onInternalTableChange(
                                                                'sort',
                                                                {
                                                                    page: page,
                                                                    sizePerPage:
                                                                        sizePerPage,
                                                                    sortField:
                                                                        column.dataField,
                                                                }
                                                            )
                                                        }
                                                    >
                                                        {column.text}
                                                    </TableSortLabel>
                                                </TableCell>
                                            )
                                        } else {
                                            return (
                                                <TableCell>
                                                    {column.text}
                                                </TableCell>
                                            )
                                        }
                                    })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <React.Fragment key={row.id}>
                                    <TableRow
                                        className="MuiTableRowMain-root"
                                        onClick={() => toggleRow(index)}
                                    >
                                        <TableCell>
                                            <IconButton
                                                style={{ padding: `0` }}
                                            >
                                                {(expandedRows.length === 0 ||
                                                    index !==
                                                        expandedRows[0]) && (
                                                    <KeyboardArrowRightIcon />
                                                )}
                                                {expandedRows.length > 0 &&
                                                    index ===
                                                        expandedRows[0] && (
                                                        <KeyboardArrowDownIcon />
                                                    )}
                                            </IconButton>
                                        </TableCell>
                                        {columns &&
                                            columns.map((column, index) => {
                                                function getValueByNestedFields(row: any, dataFields: any[]): any {
                                                    if (dataFields.length === 1) {
                                                        return row[dataFields[0]];
                                                    }

                                                    const nestedField = dataFields.shift();
                                                    return getValueByNestedFields(row[nestedField], dataFields);
                                                }

                                                const dataFields = column.dataField.split(".");
                                                const value = getValueByNestedFields(row, dataFields);

                                                return (
                                                    <>
                                                        {index === 0 && (
                                                            <TableCell
                                                                style={{
                                                                    color: colors
                                                                        .greenAccent[300],
                                                                }}
                                                            >
                                                                {value !=
                                                                    null &&
                                                                    String(
                                                                        value
                                                                    )}
                                                            </TableCell>
                                                        )}
                                                        {index > 0 && (
                                                            <TableCell>
                                                                {value !=
                                                                    null &&
                                                                    String(
                                                                        value
                                                                    )}
                                                            </TableCell>
                                                        )}
                                                    </>
                                                )
                                            })}
                                    </TableRow>
                                    {expandedRows.includes(index) && (
                                        <TableRow>
                                            <TableCell
                                                colSpan={columns.length + 1}
                                            >
                                                {expandRow(row)}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={paginationFactory.totalSize}
                    rowsPerPage={paginationFactory.sizePerPage}
                    page={paginationFactory.page - 1}
                    onPageChange={(
                        event: React.MouseEvent<HTMLButtonElement> | null,
                        page: number
                    ) =>
                        onInternalTableChange('pagination', {
                            page: page + 1,
                            sizePerPage: sizePerPage,
                        })
                    }
                    onRowsPerPageChange={(
                        event: React.ChangeEvent<
                            HTMLTextAreaElement | HTMLInputElement
                        >
                    ) =>
                        onInternalTableChange('pagination', {
                            page: 1,
                            sizePerPage: parseInt(event.target.value),
                        })
                    }
                />
            </Box>
        </Box>
    )
}

export default DataGrid
