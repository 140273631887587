import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { v4 as uuidv4 } from 'uuid'
import { FunctionComponent, useMemo } from 'react'

const AccordionExt: FunctionComponent<AccordionProp> = ({
    title,
    titleElement,
    component,
}) => {
    const uuid = useMemo(() => uuidv4(), [])

    return (
        <Accordion id={uuid}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {titleElement && (titleElement)}
                {!titleElement && title && (<Typography>{title}</Typography>)}
            </AccordionSummary>
            <AccordionDetails>{component}</AccordionDetails>
        </Accordion>
    )
}

interface AccordionProp {
    title?: string | undefined
    titleElement?: JSX.Element | undefined
    component: JSX.Element
}

export default AccordionExt
