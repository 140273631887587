import Axios from './Axios'
import { getValueByKey, removeKey } from './EncryptStorage'
import { QAM_REACT_SECURITY_KEY } from '../share/AuthConstant'

/**
 * Intercept each secure request, and attach a bearer token if it presents
 */
Axios.interceptors.request.use(
    async (config) => {
        const hanaSecurityKey = getValueByKey(QAM_REACT_SECURITY_KEY)

        if (hanaSecurityKey && hanaSecurityKey.apiKey) {
            config.headers['X-Api-Key'] = hanaSecurityKey.apiKey
            return config
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

function getSecurityKey() {
    return getValueByKey(QAM_REACT_SECURITY_KEY)
}

export function removeSecurityKey() {
    if (getSecurityKey()) {
        console.log('Remove security key...')
        removeKey(QAM_REACT_SECURITY_KEY)
    }
}

export default Axios
