import React, { FunctionComponent, useState } from 'react'
import { Box, IconButton, TextField } from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useMutation } from 'react-query'
import { ApiError } from '../../../../interfaces/ErrorType'
import ButtonExt from '../../../../components/ButtonExt'
import Header from '../../../../components/Header'
import ErrorMessage from '../../../../components/ErrorMessage'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Project, ProjectRequest, ProjectWrapper } from '../../../../interfaces/ProjectType'
import { saveProject, unsetCurrentProject } from '../../../../actions/project'
import TextareaAutosizeExt from '../../../../components/TextareaAutosize'
import SelectExt from '../../../../components/Select'
import { dataStatusOptions } from '../../../../share/DataStatusConstant'
import { connect } from 'react-redux'

const projectSchema = yup.object().shape({
    code: yup.string().required('required').min(2).max(10),
    name: yup.string().required('required').min(3),
    description: yup.string().required('required'),
    status: yup.string().required('required'),
})

const AdminProjectDetail: FunctionComponent<ProjectWrapper> = ({
                                                                   isNew,
                                                                   wrapper,
                                                                   switchProject,
                                                               }) => {

    const navigate = useNavigate()
    const [project, setProject] = useState<Project | undefined>(wrapper)
    const [projectRequest, setProjectRequest] = useState<ProjectRequest>(wrapper?.id ? {
        id: wrapper!!.id,
        code: wrapper!!.code,
        name: wrapper!!.name,
        description: wrapper!!.description,
        status: wrapper!!.status,
    } : {
        id: undefined,
        code: '',
        name: '',
        description: '',
        status: 'ACTIVE',
    })

    /**
     * Invoke an action to create/ update project
     * @param {*} e - event
     */
    const onSave = (values: ProjectRequest) => {
        projectCreateOrUpdateMutation.mutate(values, {
            onSuccess: (data) => {
                setProject(data)
                setProjectRequest(data)
                values = data
                if (values.status === 'INACTIVE') {
                    unsetProjectMutation.mutate({})
                }
            },
        })
    }

    /**
     * Mutate project create/ update
     */
    const projectCreateOrUpdateMutation = useMutation<Project, ApiError, ProjectRequest>(
        saveProject,
    )

    /**
     * Mutate unset project
     */
    const unsetProjectMutation = useMutation<any, Error, any>(unsetCurrentProject)

    if (projectCreateOrUpdateMutation.isSuccess && isNew) {
        navigate(`/admin/project`)
    }

    /**
     * Page containing project detail page
     */
    return (
        <Box m='20px'>
            {isNew && (
                <>
                    <Box
                        display="flex"
                        justifyContent="start"
                        mt="20px"
                        style={{ padding: `10px` }}
                    >
                        <IconButton
                            color="secondary"
                            onClick={() => navigate(`/admin/project`)}
                        >
                            <ArrowBackIcon /> Back
                        </IconButton>
                    </Box>

                    <Header title="Create New Project" />
                </>
            )}

            <Box style={{ marginBottom: `2em` }}>
                {projectCreateOrUpdateMutation.isError && (
                    <ErrorMessage error={projectCreateOrUpdateMutation.error} />
                )}
            </Box>

            <Formik
                onSubmit={onSave}
                initialValues={projectRequest}
                validationSchema={projectSchema}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display='grid'
                            gap='30px'
                            gridTemplateColumns='repeat(2, minmax(0,1fr))'
                        >
                            {values.id && (
                                <TextField
                                    variant='filled'
                                    type='text'
                                    label='Id'
                                    value={values.id}
                                    name='id'
                                />
                            )}

                            {isNew && (
                                <>
                                    <TextField
                                        variant="filled"
                                        type="text"
                                        label="Code"
                                        onChange={handleChange}
                                        value={values.code}
                                        name="code"
                                        inputProps={{
                                            minLength: 2,
                                            maxLength: 10,
                                        }}
                                        error={!!touched.code && !!errors.code}
                                        helperText={touched.code && errors.code}
                                    />

                                    <TextField
                                        variant="filled"
                                        type="text"
                                        label="Name"
                                        onChange={handleChange}
                                        value={values.name}
                                        name="name"
                                        inputProps={{
                                            minLength: 3,
                                        }}
                                        error={!!touched.name && !!errors.name}
                                        helperText={touched.name && errors.name}
                                    />
                                </>
                            )}
                        </Box>

                        <Box
                            display='grid'
                            mt='20px'
                            gap='30px'
                            gridTemplateColumns='repeat(1, minmax(0,1fr))'
                        >
                            <TextareaAutosizeExt
                                label="Description"
                                name="description"
                                onChangeEvent={handleChange}
                                value={values.description}
                                error={!!touched.description && !!errors.description}
                                helperText={touched.description && errors.description}
                                minRows={10}
                                maxRows={10}
                            />

                            <SelectExt
                                name='status'
                                multiSelection={false}
                                isClearable={false}
                                label='Status'
                                selectedValue={values.status}
                                options={dataStatusOptions}
                                onSelect={(v) => {
                                    setProjectRequest({
                                        ...projectRequest,
                                        status: v,
                                    })
                                    values.status = v
                                }}
                                error={!!touched.status && !!errors.status}
                                helperText={touched.status && errors.status}
                            />
                        </Box>

                        <Box
                            display='grid'
                            mt='20px'
                            gap='30px'
                            gridTemplateColumns='repeat(2, minmax(0,1fr))'
                        >
                            {project?.createdBy && (
                                <>
                                    <TextField
                                        variant='filled'
                                        type='text'
                                        label='Created By'
                                        value={project.createdBy}
                                        name='createdBy'
                                    />
                                    <TextField
                                        variant='filled'
                                        type='text'
                                        label='Created At'
                                        value={project.createdAt}
                                        name='createdBy'
                                    />
                                </>
                            )}

                            {project?.updatedBy && (
                                <>
                                    <TextField
                                        variant='filled'
                                        type='text'
                                        label='Updated By'
                                        value={project.updatedBy}
                                        name='updatedBy'
                                    />
                                    <TextField
                                        variant='filled'
                                        type='text'
                                        label='Updated At'
                                        value={project.updatedAt}
                                        name='updatedAt'
                                    />
                                </>
                            )}
                        </Box>

                        <Box
                            display='flex'
                            justifyContent='end'
                            mt='20px'
                            gap='20px'
                        >
                            <ButtonExt
                                type='submit'
                                value={
                                    projectCreateOrUpdateMutation.isLoading
                                        ? 'Saving'
                                        : 'Save'
                                }
                                disabled={
                                    projectCreateOrUpdateMutation.isLoading
                                }
                            />
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

/**
 * Connect and retrieve the current switch project id through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { switchProject: state.switchProject.project }
}

export default connect(mapStateToProps)(AdminProjectDetail)