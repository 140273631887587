import React, { useState } from 'react'
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar'
import 'react-pro-sidebar/dist/css/styles.css'
import { Box, IconButton, Typography, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'
import { tokens } from '../../theme'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import BugReportIcon from '@mui/icons-material/BugReport';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import { SigninResponse } from '../../interfaces/UserType'
import { connect } from 'react-redux'

interface ItemProps {
    title: string
    to: string
    icon: React.ReactNode
    selected: string
    setSelected: (title: string) => void
}

const Item = ({ title, to, icon, selected, setSelected }: ItemProps) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    return (
        <MenuItem
            active={selected === title}
            style={{ color: colors.grey[100] }}
            onClick={() => setSelected(title)}
            icon={icon}
        >
            <Typography>{title}</Typography>
            <Link to={to} />
        </MenuItem>
    )
}

const Sidebar = (props: { user: SigninResponse }) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [isCollapsed, setIsCollapsed] = useState(false)
    const [selected, setSelected] = useState('Dashboard')
    const { user } = props

    return (
        <Box
            sx={{
                '& .pro-sidebar': {
                    minHeight: '100vh',
                },
                '& .pro-sidebar-inner': {
                    background: `${colors.primary[400]} !important`,
                },
                '& .pro-icon-wrapper': {
                    backgroundColor: 'transparent !important',
                },
                '& .pro-inner-item': {
                    padding: '5px 35px 5px 20px !important',
                },
                '& .pro-inner-item:hover': {
                    color: '#868dfb !important',
                },
                '& .pro-menu-item.active': {
                    color: '#6870fa !important',
                },
            }}
        >
            <ProSidebar collapsed={isCollapsed}>
                <Menu iconShape="square">
                    {/* Logo and Menu Items */}
                    <MenuItem
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                        style={{
                            margin: '10px 0 10px 0',
                            color: colors.grey[100],
                        }}
                    >
                        {!isCollapsed && (
                            <Box alignItems="center">
                                {/*<Box textAlign="center">*/}
                                {/*    <img*/}
                                {/*        alt="QAM"*/}
                                {/*        width="auto"*/}
                                {/*        height="80px"*/}
                                {/*        src={`../../logo192.png`}*/}
                                {/*    />*/}
                                {/*</Box>*/}
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    ml="40px"
                                >
                                    <Typography
                                        variant="h4"
                                        color={colors.grey[100]}
                                    >
                                        QAM
                                    </Typography>
                                    <IconButton
                                        onClick={() =>
                                            setIsCollapsed(!isCollapsed)
                                        }
                                    >
                                        <MenuOutlinedIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        )}
                    </MenuItem>
                    {/* User */}
                    {!isCollapsed && (
                        <Box mb="10px">
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <img
                                    alt="profile-user"
                                    width="100px"
                                    height="100px"
                                    src={`../../assets/user.png`} //ref: https://icons8.com/icon/z-JBA_KtSkxG/test-account
                                />
                            </Box>

                            {user?.username && (
                                <Box textAlign="center">
                                    <Typography
                                        variant="h5"
                                        color={colors.grey[100]}
                                        fontWeight="bold"
                                        sx={{ m: '10px 0 0 0' }}
                                    >
                                        {user.username.toUpperCase()}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    )}

                    {/* Menu Items */}
                    <Box paddingLeft={isCollapsed ? undefined : '10%'}>
                        <Item
                            title="Dashboard"
                            to="/"
                            icon={<HomeOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{ m: '5px 0 5px 20px' }}
                        >
                            Project
                        </Typography>

                        <Item
                            title="Manage TestCase"
                            to="/project/testcase"
                            icon={<BugReportIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        <Item
                            title="Progress"
                            to="/project/progress"
                            icon={<DeveloperBoardIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        {user?.username && user.username.toLowerCase() === 'admin' && (
                            <>
                                <Typography
                                    variant="h6"
                                    color={colors.grey[300]}
                                    sx={{ m: '5px 0 5px 20px' }}
                                >
                                    Admin
                                </Typography>

                                <Item
                                    title="Manage Project"
                                    to="/admin/project"
                                    icon={<AccountTreeIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                            </>
                        )}
                    </Box>
                </Menu>
            </ProSidebar>
        </Box>
    )
}

/**
 * Connect and retrieve the current user through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { user: state.user.user }
}

export default connect(mapStateToProps)(Sidebar)
