/**
 * Create Auth Provider
 */
import { createAuthProvider } from 'react-token-auth'
import Axios from '../extensions/Axios'
import {
    getValueByKey,
    updateValueByKey,
    removeKey,
} from '../extensions/EncryptStorage'
import { QAM_REACT_SECURITY_KEY } from '../share/AuthConstant'
import { removeAllConfigurations } from './configuration'
import store from '../redux/store'
import { UPDATE_USER } from '../redux/actionType'
import SecureAxios from '../extensions/SecureAxios'

/**
 * Create default encrypt store which compatible with 'react-token-auth'
 *
 * @param initData
 * @returns
 */
export const createDefaultEncryptStore = (initData = {}) => {
    const data: any = initData

    const getItem = (key: string) => {
        return JSON.stringify(data[key])
    }

    const setItem = (key: string, value: SecurityKey) => {
        data[key] = value
        updateValueByKey(key, value)
    }

    const removeItem = (key: string) => {
        delete data[key]
        removeKey(key)
    }

    return { getItem, setItem, removeItem }
}

export const [useAuth, authFetch, login, logout] =
    createAuthProvider<SecurityKey>({
        accessTokenKey: 'apiKey',
        localStorageKey: QAM_REACT_SECURITY_KEY,
        storage: createDefaultEncryptStore({
            [QAM_REACT_SECURITY_KEY]: getValueByKey(QAM_REACT_SECURITY_KEY),
        }),
    })

/**
 * Complete signout
 */
export async function signout() {
    try {
        await SecureAxios.post('/logout', {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    } catch (error) {
        // Add console log but prevent throwing exception
        // In case there is an error upon invoking logout api
        console.log(error)
    } finally {
        removeAllConfigurations()
        logout()
    }
}

/**
 * Signin
 * @param {*} credential
 * @returns
 */
export async function signin(credential: any) {
    const { data } = await Axios.post('/login', credential, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    store.dispatch({
        type: UPDATE_USER,
        payload: data,
    })

    return data
}

export interface SecurityKey {
    apiKey: string
    username: string
}
