import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Box, FormHelperText, useTheme } from '@mui/material'
import { tokens } from '../theme'
import dayjs, { Dayjs } from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { DateTimePicker } from '@mui/x-date-pickers'

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'
const DateTimePickerExt: FunctionComponent<DateTimePickerProp> = ({
                                                              value,
                                                              min,
                                                              max,
                                                              label,
                                                              required = false,
                                                              editable = true,
                                                              onChange,
                                                              error,
                                                              helperText,
                                                          }) => {
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null)
    const uuid = useMemo(() => uuidv4(), [])
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    useEffect(() => {
        setSelectedDate(value ? dayjs(value) : null)
    }, [value])

    const onInternalChangeEvent = async (date: any) => {
        if (onChange) {
            const formattedDate = toDateStringFormat(date);
            onChange(formattedDate)
        }

        setSelectedDate(date)
    }

    return (
        <FormHelperText error={error}>
            <Box
                display='grid'
                sx={{
                    '& .DatePicker-root': {
                        color: `${colors.greenAccent[200]} !important`,
                    },

                    '& .MuiTextField-root': {
                        width: `100%`,
                    },
                }}
            >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                        <DateTimePicker
                            key={uuid}
                            label={label}
                            value={selectedDate}
                            minDate={min ? dayjs(min) : undefined}
                            maxDate={max ? dayjs(max) : undefined}
                            onChange={onInternalChangeEvent}
                            readOnly={!editable}
                        />
                    </DemoContainer>
                </LocalizationProvider>

                {helperText}
            </Box>
        </FormHelperText>
    )
}

export const toDateStringFormat = (date: Date) => {
    return dayjs(date).format(DATE_FORMAT)
}

/**
 * Contains the specific props type that can be passing
 */
interface DateTimePickerProp {
    label?: string | undefined
    value: string | undefined
    min?: string | undefined
    max?: string | undefined
    required?: boolean
    editable?: boolean
    onChange?: (v: string) => void // Handle event upon onclick
    error?: boolean | undefined
    helperText?: React.ReactNode
}

export default DateTimePickerExt
