import React, { useEffect, useMemo, useState } from 'react'
import { mediaQuery } from './MediaQuery'
import { v4 as uuidv4 } from 'uuid'
import store from '../redux/store'
import { LOAD_TABLE_DATA } from '../redux/actionType'
import { connect } from 'react-redux'
import { useAuthQueryWithQueryFunction } from '../extensions/UseAuthQuery'
import styled from 'styled-components'
import { Box, TextField } from '@mui/material'
import DataGrid, {
    DataGridColumnDescription,
    TablePaginationChangeProp,
} from './DataGrid'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import RestoreIcon from '@mui/icons-material/Restore'
import { useNavigate } from 'react-router-dom'
import { ApiError } from '../interfaces/ErrorType'
import ErrorMessage from './ErrorMessage'
import ButtonExt from './ButtonExt'

// Create a reusable styled div component
const StyledSearchCriteriaDiv = styled.div<{ searchFilterCols: number }>`
    display: grid;
    grid-template-columns: ${({ searchFilterCols }) =>
    [...Array(searchFilterCols)].map(() => '1fr ')};
    grid-template-rows: auto;
    column-gap: 1em;
    row-gap: 1em;
    margin: 1rem;
    text-align: left;

    ${mediaQuery('tablet')`
      grid-template-columns: auto;
  `};
`

const DataGridFilter = (props: DataGridFilterProp) => {
    const {
        keyField,
        useQueryKey,
        reduxTableData,
        columns,
        onSearchPageUseQueryEvent,
        searchFilterCols = 2,
        customSearchOptions,
        resetCustomSearchOptions,
        change,
        customSearchOptionsRenderer,
        createPageUrl,
        expandRow,
    } = props

    const navigate = useNavigate()
    // Filter specific table data based on useQueryKey
    const tableData = reduxTableData.find(
        (each: any) => each?.key === useQueryKey
    )?.data
    const sizePerPageList = [10, 25, 50, 100]
    const [keyword, setKeyword] = useState<string>('')
    const [reset, setReset] = useState<boolean>(false)
    const defaultCustomSearchOptions = useMemo(() => customSearchOptions, [])
    const defaultSearchOptions = {
        uuid: undefined,
        keyword: '',
        customSearchOptions: { ...defaultCustomSearchOptions },
        pageOptions: {
            page: 1,
            pageLimit: sizePerPageList[sizePerPageList.length - 1],
        },
        sortOptions: undefined,
    }

    const [searchOptions, setSearchOptions] = useState<SearchOptionsProp>({
        ...defaultSearchOptions,
    })

    const { data, error, status, refetch } = useAuthQueryWithQueryFunction<
        any,
        ApiError,
        any
        >(
        [useQueryKey, searchOptions],
        () => onSearchPageUseQueryEvent(searchOptions),
        {
            refetchOnWindowFocus: false,
            retry: 1,
            enabled: false, // disable this query from automatically running
            onSuccess(data) {
                store.dispatch({
                    type: LOAD_TABLE_DATA,
                    payload: { key: useQueryKey, data: data?.data?.content ? data?.data?.content : data?.content },
                })
                if (reset) {
                    setReset(false)
                }
            },
            onError(error) {
                if (reset) {
                    setReset(false)
                }
            },
        }
    )

    useEffect(() => {
        refetch()
    }, [searchOptions])

    useEffect(() => {
        onSearch()
    }, [change])


    const totalSize: number = data?.data?.totalElements
        ? data.data.totalElements
        :
        (data?.totalElements ? data?.totalElements : 0)

    const handleTableChange = async (
        type: string,
        valueChange: TablePaginationChangeProp
    ) => {
        if (type === 'sort') {
            setSearchOptions({
                ...searchOptions,
                pageOptions: {
                    ...searchOptions.pageOptions,
                    page: 1,
                },
                sortOptions: {
                    ...searchOptions.sortOptions,
                    sortField: valueChange.sortField,
                    sortOrder: valueChange.sortOrder,
                },
            })
        }

        if (type === 'pagination') {
            setSearchOptions({
                ...searchOptions,
                uuid: uuidv4(),
                pageOptions: {
                    ...searchOptions.pageOptions,
                    page: valueChange.page === 0 ? 1 : valueChange.page,
                    pageLimit: valueChange.sizePerPage,
                },
            })
        }
    }

    const onSearch = async () => {
        setSearchOptions({
            ...searchOptions,
            uuid: uuidv4(),
            pageOptions: {
                ...searchOptions.pageOptions,
                page: 1,
            },
            keyword: keyword,
            customSearchOptions: customSearchOptions,
        })
    }

    const onReset = async () => {
        setReset(true)
        setKeyword('')
        setSearchOptions({
            ...defaultSearchOptions,
            uuid: uuidv4(),
        })

        if (resetCustomSearchOptions) {
            resetCustomSearchOptions({
                ...defaultSearchOptions.customSearchOptions,
            })
        }
    }

    return (
        <>
            <Box style={{ marginBottom: `2em` }}>
                {status === 'error' && <ErrorMessage error={error} />}
            </Box>
            <StyledSearchCriteriaDiv searchFilterCols={searchFilterCols}>
                <div
                    style={{
                        display: `grid`,
                        gridTemplateColumns: `auto`,
                        gridTemplateRows: `auto`,
                    }}
                >
                    <TextField
                        style={{ width: `auto`, height: `auto` }}
                        name="keyword"
                        value={keyword}
                        label="Search keyword..."
                        onChange={(e) => setKeyword(e.target.value)}
                    />
                </div>

                {customSearchOptionsRenderer}
            </StyledSearchCriteriaDiv>

            <StyledSearchCriteriaDiv searchFilterCols={1}>
                <div style={{ textAlign: `right` }}>
                    {createPageUrl && (
                        <ButtonExt
                            style={{
                                width: `auto`,
                                height: `auto`,
                                margin: `5px`,
                            }}
                            icon={<AddIcon />}
                            value="Create"
                            onClickEvent={() => navigate(createPageUrl)}
                        />
                    )}
                    <ButtonExt
                        style={{
                            width: `auto`,
                            height: `auto`,
                            margin: `5px`,
                        }}
                        icon={<SearchIcon />}
                        value={
                            status === 'loading' && !reset
                                ? 'Searching...'
                                : 'Search'
                        }
                        onClickEvent={() => onSearch()}
                    />
                    <ButtonExt
                        style={{
                            width: `auto`,
                            height: `auto`,
                            margin: `5px`,
                        }}
                        icon={<RestoreIcon />}
                        value={reset ? 'Resetting...' : 'Reset'}
                        onClickEvent={() => onReset()}
                    />
                </div>
            </StyledSearchCriteriaDiv>

            <DataGrid
                keyField={keyField}
                columns={columns}
                data={tableData ? tableData : []}
                paginationFactory={{
                    page: searchOptions.pageOptions.page,
                    sizePerPage: searchOptions.pageOptions.pageLimit,
                    totalSize: totalSize,
                }}
                expandRow={expandRow}
                onTableChange={(type, valueChange) =>
                    handleTableChange(type, valueChange)
                }
            />
        </>
    )
}

/**
 * Contains the specific props type that can be passing
 */
interface DataGridFilterProp {
    keyField: string
    useQueryKey: string
    reduxTableData: any
    columns: DataGridColumnDescription[]
    onSearchPageUseQueryEvent: (searchOptions: SearchOptionsProp) => void // Handle event upon search page change
    searchFilterCols?: number
    customSearchOptions?: any
    resetCustomSearchOptions?: (setCustomSearchOptions?: any) => void // Reset
    change?: string | undefined
    customSearchOptionsRenderer?: JSX.Element
    createPageUrl?: string
    expandRow: (row: any) => any
}

export interface SearchOptionsProp {
    uuid: string | undefined
    keyword: string | undefined
    customSearchOptions: any
    pageOptions: PageOptionsProp
    sortOptions: SortOptionsProp | undefined
}

interface PageOptionsProp {
    page: number
    pageLimit: number
}

interface SortOptionsProp {
    sortField?: string | undefined
    sortOrder?: string | undefined
}

/**
 * Connect and retrieve the current table data through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { reduxTableData: state.tableData.data }
}

export default connect(mapStateToProps)(DataGridFilter)
