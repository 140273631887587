import {
    getValueByKey,
    updateValueByKey,
    removeKey,
} from '../../extensions/EncryptStorage'
import { UNSET_UESR, UPDATE_USER } from '../actionType'

export const CURRENT_UESR = 'CURRENT_UESR'

/**
 * Default state declaration
 */
const initialState = {
    user: getValueByKey(CURRENT_UESR),
}

/**
 * Update/ load user data redux state through reducer
 *
 * @param {*} state - immutable state for page/ component update when there is changes depending on action
 * @param {*} action - an action contains type of changes, and payload for update
 * @returns
 */
export default function user(state = initialState, action) {
    switch (action.type) {
        case UPDATE_USER: {
            updateValueByKey(CURRENT_UESR, action.payload)
            return Object.assign({}, state, {
                user: action.payload,
            })
        }
        case UNSET_UESR: {
            removeKey(CURRENT_UESR)
            return Object.assign({}, state, {
                user: undefined,
            })
        }
        default:
            return state
    }
}
