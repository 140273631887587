import { Box } from '@mui/material'
import Header from '../../components/Header'

const Dashboard = () => {
    return (
        <Box m="20px">
            <Box justifyContent="space-between" alignItems="center">
                <Header title="DASHBOARD" subtitle="Summary" />
            </Box>
        </Box>
    )
}

export default Dashboard
