import SecureAxios from '../extensions/SecureAxios'
import store from '../redux/store'
import {
    LOAD_ACTIVE_PROJECTS_LIST, REMOVE_ACTIVE_PROJECTS,
    SWITCH_PROJECT,
    UNSET_PROJECT, UPDATE_ACTIVE_PROJECTS,
    UPDATE_PROJECT,
    UPDATE_TABLE_DATA_ITEM,
} from '../redux/actionType'
import { toDateStringFormat } from '../components/DateTimePickerExt'

export const CURRENT_PROJECT = 'CURRENT_PROJECT'

/**
 * An action to fetch project
 * @param searchOptions
 * @returns {Promise<*>}
 */
export const fetchProject = async (searchOptions) => {
    let projectSearchUrl = `/projects?keyword=${searchOptions.keyword}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`
    if (
        searchOptions.sortOptions?.sortField &&
        searchOptions.sortOptions?.sortOrder
    ) {
        projectSearchUrl += `&sort=${searchOptions.sortOptions.sortField}&sortDir=${searchOptions.sortOptions.sortOrder}`
    }

    const { data } = await SecureAxios.get(projectSearchUrl)
    return data
}

/**
 * An action to fetch project by id
 * @param projectId
 * @returns {Promise<*>}
 */
export const fetchProjectById = async (projectId) => {
    const { data } = await SecureAxios.get(`/project/${projectId}`)
    return data.data
}

/**
 * An action to download project test report
 * @param projectId
 * @returns {Promise<*>}
 */
export const downloadProjectProgressTestReport = async (projectProgress) => {
    const project = store.getState().switchProject.project
    const { data } = await SecureAxios.post(`/project/${project.id}/progress/${projectProgress.id}/generate/report`, {}, {
        responseType: 'arraybuffer',
    })

    // Create a Blob from the response data
    const file = new Blob([data], { type: 'application/octet-stream' });
    // Create a URL for the Blob
    const fileURL = URL.createObjectURL(file);
    // Create a link element
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = `${project.name} Test Report for ${toDateStringFormat(projectProgress.updatedAt)}.xlsx`; // Set the desired file name and extension

    // Append the link to the document body and click it
    document.body.appendChild(link);
    link.click();

    // Clean up the created URL and remove the link element
    URL.revokeObjectURL(fileURL);
    document.body.removeChild(link);
}

/**
 * An action to download project test summary report
 * @param projectId
 * @returns {Promise<*>}
 */
export const downloadProjectProgressTestSummaryReport = async (projectProgress) => {
    const project = store.getState().switchProject.project
    const { data } = await SecureAxios.post(`/project/${project.id}/progress/${projectProgress.id}/generate/summary/report`, {}, {
        responseType: 'arraybuffer',
    })

    // Create a Blob from the response data
    const file = new Blob([data], { type: 'application/octet-stream' });
    // Create a URL for the Blob
    const fileURL = URL.createObjectURL(file);
    // Create a link element
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = `${project.name} Test Summary Report for ${toDateStringFormat(projectProgress.updatedAt)}.xlsx`; // Set the desired file name and extension

    // Append the link to the document body and click it
    document.body.appendChild(link);
    link.click();

    // Clean up the created URL and remove the link element
    URL.revokeObjectURL(fileURL);
    document.body.removeChild(link);
}

/**
 * An action to fetch project progress by id
 * @param projectId
 * @returns {Promise<*>}
 */
export const fetchProjectProgressById = async (projectId, projectProgressId, searchOptions) => {
    if (!searchOptions) {
        const { data } = await SecureAxios.get(`/project/${projectId}/progress/${projectProgressId[projectProgressId.length - 1]}`)
        return data.data
    } else {
        let currentProjectProgressUrl = `/project/${projectId}/progress/${projectProgressId[projectProgressId.length - 1]}?keyword=${searchOptions.keyword}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`
        if (searchOptions.customSearchOptions?.statuses?.length) {
            currentProjectProgressUrl += `&statuses=${searchOptions.customSearchOptions.statuses.join(
                ','
            )}`
        }

        const { data } = await SecureAxios.get(currentProjectProgressUrl)
        return data.data
    }
}

/**
 * An action to fetch project test cases
 * @param searchOptions
 * @returns {Promise<*>}
 */
export const fetchProjectTestCase = async (searchOptions) => {
    let projectTestCaseSearchUrl = `/project/${store.getState().switchProject.project.id}/testcases?keyword=${searchOptions.keyword}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`
    if (
        searchOptions.sortOptions?.sortField &&
        searchOptions.sortOptions?.sortOrder
    ) {
        projectTestCaseSearchUrl += `&sort=${searchOptions.sortOptions.sortField}&sortDir=${searchOptions.sortOptions.sortOrder}`
    }

    const { data } = await SecureAxios.get(projectTestCaseSearchUrl)
    return data
}

/**
 * An action to fetch active project
 * @returns {Promise<*>}
 */
export const fetchActiveProjects = async () => {
    let projectSearchUrl = `/projects/active`
    const { data } = await SecureAxios.get(projectSearchUrl)

    store.dispatch({
        type: LOAD_ACTIVE_PROJECTS_LIST,
        payload: data.data,
    })

    return data.data
}

/**
 * An action to save project
 */
export async function saveProject(project) {
    const requestBody = {
        ...project,
    }
    let projectUrl = `/admin/project`
    if (project.id) {
        projectUrl += `/${project.id}/update`

        const { data } = await SecureAxios.put(projectUrl, requestBody, {
            headers: {
                'Content-Type': 'application/json',
            },
        })

        const result = data.data

        if (store.getState().switchProject?.project?.id === result.id) {
            store.dispatch({ type: UPDATE_PROJECT, payload: result })
        }

        store.dispatch({
            type: UPDATE_TABLE_DATA_ITEM,
            payload: { key: 'admin-projects', keyField: 'id', data: result },
        })

        if (data.data.status === 'INACTIVE') {
            store.dispatch({
                type: REMOVE_ACTIVE_PROJECTS,
                payload: {
                    keyField: 'id',
                    keyValue: data.data.id,
                },
            })
        } else {
            store.dispatch({
                type: UPDATE_ACTIVE_PROJECTS,
                payload: {
                    keyField: 'id',
                    data: data.data,
                },
            })
        }

        return result
    } else {
        const { data } = await SecureAxios.post(projectUrl, requestBody, {
            headers: {
                'Content-Type': 'application/json',
            },
        })

        if (data.data.status === 'ACTIVE') {
            store.dispatch({
                type: UPDATE_ACTIVE_PROJECTS,
                payload: { data: data.data },
            })
        }

        return data.data
    }
}

/**
 * An action to save project test case
 */
export async function saveProjectTestCase(projectTestCase) {
    const requestBody = {
        ...projectTestCase,
    }
    let projectTestCaseUrl = `/project/${projectTestCase.projectId}/testcase`
    if (projectTestCase.id) {
        projectTestCaseUrl += `/${projectTestCase.id}/update`

        const { data } = await SecureAxios.put(projectTestCaseUrl, requestBody, {
            headers: {
                'Content-Type': 'application/json',
            },
        })

        const result = data.data

        store.dispatch({
            type: UPDATE_TABLE_DATA_ITEM,
            payload: { key: `project-test-case-${store.getState().switchProject.project.id}`, keyField: 'id', data: result },
        })

        return result
    } else {
        const { data } = await SecureAxios.post(projectTestCaseUrl, requestBody, {
            headers: {
                'Content-Type': 'application/json',
            },
        })

        return data.data
    }
}

/**
 * An action to delete project test case
 */
export async function deleteProjectTestCase(projectTestCase) {
    await SecureAxios.delete(`/project/${projectTestCase.projectId}/testcase/${projectTestCase.id}/delete`, {}, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

export async function duplicateProjectTestCase(projectTestCase) {
    const { data } = await SecureAxios.put(`/project/${projectTestCase.projectId}/testcase/${projectTestCase.id}/duplicate`, {}, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    return data.data
}


/**
 * An action to save project test case progress
 */
export async function saveProjectTestCaseProgress(projectTestCaseProgressWrapper) {
    const requestBody = {
        ...projectTestCaseProgressWrapper.projectTestCaseProgress,
    }

    const { data } = await SecureAxios.put(`/project/progress/${projectTestCaseProgressWrapper.projectProgressId}/testcase/${projectTestCaseProgressWrapper.testCaseProgressId}/update`, requestBody, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    const result = data.data

    store.dispatch({
        type: UPDATE_TABLE_DATA_ITEM,
        payload: { key: `project-test-case-progress-${store.getState().switchProject.project.id}`, keyField: 'id', data: result },
    })

    return result
}

/**
 * An action to create new project progress
 */
export async function saveProjectProgress(projectProgress) {
    const requestBody = {
        ...projectProgress,
    }

    const { data } = await SecureAxios.post(`/project/${projectProgress.projectId}/progress`, requestBody, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    return data.data
}

/**
 * An action to close project progress
 */
export async function closeProjectProgress(projectProgressId) {
    const { data } = await SecureAxios.post(`/project/${store.getState().switchProject.project.id}/progress/${projectProgressId}/complete`, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    return data.data
}

/**
 * An action to switch current project
 */
export async function switchCurrentProject(project) {
    store.dispatch({ type: SWITCH_PROJECT, payload: project })
}

/**
 * An action to switch current project
 */
export async function unsetCurrentProject() {
    store.dispatch({ type: UNSET_PROJECT, payload: undefined })
}
