/**
 * Default state declaration
 */
import { UPDATE_TAB_INDEX } from '../actionType'

const initialState = {
    data: [],
}

/**
 * Update table data redux state through reducer depending on key
 *
 * @param {*} state - immutable state for page/ component update when there is changes depending on action
 * @param {*} action - an action contains type of changes, and payload for update
 * @returns
 */
export default function tabsIndexes(state = initialState, action) {
    switch (action.type) {
        case UPDATE_TAB_INDEX: {
            const payload = action.payload

            const cloneExistingData = [...state.data]
            const existingItem = cloneExistingData.find(
                (each) => each.key === payload.key
            )

            if (existingItem) {
                existingItem.index = payload.index
            } else {
                cloneExistingData.push(payload)
            }

            return Object.assign({}, state, {
                data: cloneExistingData,
            })
        }
        default:
            return state
    }
}
